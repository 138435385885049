// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-signup-index-jsx": () => import("../src/pages/signup/index.jsx" /* webpackChunkName: "component---src-pages-signup-index-jsx" */),
  "component---src-pages-signup-thanks-jsx": () => import("../src/pages/signup/thanks.jsx" /* webpackChunkName: "component---src-pages-signup-thanks-jsx" */),
  "component---src-pages-terms-jsx": () => import("../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

